<template>
    <section id="hero" class="hero d-flex align-items-center text-danger">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 d-flex flex-column justify-content-center pb-2 hero-content rounded">
						<h1 data-aos="fade-up" >We Build World-Class Apps for Your Business</h1>
						<h2 data-aos="fade-up" >We are Team of Talented Engineers, Developers, Designers, and Strategists.</h2>
						<div data-aos="fade-up" data-aos-delay="600">
							<div class="text-center text-lg-start">
								<a href="#about" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
									<span>Get Started</span>
									<i class="bi bi-arrow-right"></i>
								</a>
							</div>
						</div>
					</div>
					<!-- <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
						<img src="assets/img/hero-img.png" class="img-fluid" alt="" />
					</div> -->
				</div>
			</div>
    </section>
</template>

<script setup>
	
</script>

<style>

</style>