<template>
   <section id="about" class="about">
      <div class="container" data-aos="fade-up">
         <div class="row gx-0">
            <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
               <div class="content text-justify">
                  <h3>Who We Are</h3>
                  <h2>Apps Built Right is specialized in creating innovative and user-friendly solutions that drive growth for businesses across industries. </h2>
                  <p class="">
                     With a team of talented developers, designers, and strategists, we craft custom app solutions tailored to your unique needs, ensuring seamless functionality, intuitive interfaces, and maximum impact.
                  </p>
                  <!-- <div class="text-center text-lg-start">
                     <a href="#" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                        <span>Read More</span>
                        <i class="bi bi-arrow-right"></i>
                     </a>
                  </div> -->
               </div>
            </div>

            <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
               <img src="/assets/img/about-us.png" class="img-fluid" alt="" />
            </div>
         </div>
      </div>
   </section>
</template>

<script setup>

</script>

<style>

</style>