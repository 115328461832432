import { createApp } from 'vue'
import Toast from 'vue-toastification'
import { createHead } from '@unhead/vue'

import 'vue-toastification/dist/index.css'
import App from './App.vue'
import router from './router'


createApp(App)
   .use(Toast)
   .use(createHead())
   .use(router)
   .mount('#app')
