<template>
	<Header/>
	<router-view
		:toast='toast'
		:route='route'
	>
	</router-view>
	<Footer />
</template>

<script setup>
	import { computed, onMounted, ref, watch } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import { useToast } from 'vue-toastification';

   import { isAuthenticated } from './auth';
	import Header from './components/Header'
	import Footer from './components/Footer'
	//import Button from '../components/utils/Button'

	//***Reactive state, data
	const route = useRoute();
   const router = useRouter();
	const toast = useToast();
   const isAuth = ref( isAuthenticated() );


	//***Methods
	const	updateTitle = () => {
		let title = route.meta.title;
		title = title || 'Home - Apps Built Right' ;
		document.title = title;
	};

	const handleLogin = ( data ) => {
      localStorage.setItem( 'userData', data );
      isAuth.value = data;
   };
   
   const handleLogout = () => {
      localStorage.setItem( 'userData', '' );
      isAuth.value = '';
		toast.info( 'Logged Out' )
      router.push( { name: 'Login' } );
   };
  
  	const setAuth = () => {
      isAuth.value = isAuthenticated();
   };
 
	const aosInit = () =>
	{
		AOS.init({
			duration: 1000,
			easing: "ease-in-out",
			once: true,
			mirror: false
		});
	};

	
	//***Computed properties
	const homePage = computed( () => {
		return route.path === '/';
	});


	//***onMounted properties
	onMounted(() => {
		aosInit();
		updateTitle();
		setAuth();
	});


	//***watch properties
	watch( route, () => {
		updateTitle();
	});
	
	watch( isAuth, () => {
		setAuth();
	});
</script>

<style>

</style>
