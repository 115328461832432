<template>
  <footer id="footer" class="footer">
      <div class="footer-top">
         <div class="container">
            <div class="row gy-4">
               <div class="col-lg-5 col-md-12 footer-info">
                  <router-link to="/" class="logo d-flex align-items-center">
                     <img src="../assets/logo.png" alt="" />
                     <span>Apps Built Right</span>
                  </router-link>
                  <p>Kindly Specify What You Need, And Let's Build Something Amazing!</p>
                  <div class="social-links mt-4">
                     <a href="https://github.com/Ez001" class="github" target="_blank" rel="noopener noreferrer"><i class="bi bi-github"></i></a>
                     <a href="https://m.facebook.com/ezra00100" class="facebook" target="_blank" rel="noopener noreferrer"><i class="bi bi-facebook"></i></a>
                     <a href="https://www.linkedin.com/in/ezra00100" class="linkedin" target="_blank" rel="noopener noreferrer"><i class="bi bi-linkedin"></i></a>
                  </div>
               </div>

               <div class="col-lg-2 col-6 footer-links">
                     <h4>Useful Links</h4>
                     <ul>
                        <li><i class="bi bi-chevron-right"></i> <router-link class="nav-link scrollto active" to="#hero">Home</router-link></li>
                        <li><i class="bi bi-chevron-right"></i> <router-link class="nav-link scrollto" to="#about">About Us</router-link></li>
                     </ul>
               </div>

               <div class="col-lg-2 col-6 footer-links">
                     <h4>Our Services</h4>
                     <ul>
                        <li><i class="bi bi-chevron-right"></i> <router-link to="#">Web Design</router-link></li>
                        <li><i class="bi bi-chevron-right"></i> <router-link to="#">Software Development</router-link></li>
                        <li><i class="bi bi-chevron-right"></i> <router-link to="#">Web Development</router-link></li>
                        <li><i class="bi bi-chevron-right"></i> <router-link to="#">Product Management</router-link></li>
                        <li><i class="bi bi-chevron-right"></i> <router-link to="#">Graphic Design</router-link></li>
                     </ul>
               </div>

               <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                     <h4>Contact Us</h4>
                     <p>
                        <strong>Phone:</strong> +2348103855539<br />
                        <strong>Email:</strong> info@appsbuiltright.com.ng<br />
                     </p>
               </div>
            </div>
         </div>
      </div>

      <div class="container">
         <div class="copyright">
            Copyright &copy; 2023-{{ currYear }} <strong><span>ABR</span></strong>. All Rights Reserved
         </div>
         <div class="credits">
            Developed by <a href="https://appsbuiltright.com.ng" target="_blank" rel="noopener noreferrer">ABR</a>
         </div>
      </div>
   </footer>
</template>

<script setup>
   const currYear = new Date().getFullYear();
</script>

<style>

</style>