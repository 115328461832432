<template>
   <section id="counts" class="counts">
      <div class="container" data-aos="fade-up">
         <div class="row gy-4">
            <div class="col-lg-3 col-md-6">
               <div class="count-box">
                  <i class="bi bi-emoji-smile"></i>
                  <div>
                     <span data-purecounter-start="0" data-purecounter-end="60" data-purecounter-duration="1" class="purecounter"></span>
                     <p>Happy Clients</p>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-6">
               <div class="count-box">
                  <i class="bi bi-journal-richtext" style=" color: '#ee6c20' "></i>
                  <div>
                     <span data-purecounter-start="0" data-purecounter-end="105" data-purecounter-duration="1" class="purecounter"></span>
                     <p>Projects</p>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-6">
               <div class="count-box">
                     <i class="bi bi-headset" style=" color: '#15be56' "></i>
                     <div>
                        <span data-purecounter-start="0" data-purecounter-end="2000" data-purecounter-duration="1" class="purecounter"></span>
                        <p>Hours Of Support</p>
                     </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-6">
               <div class="count-box">
                     <i class="bi bi-people" style=" color: '#bb0852' "></i>
                     <div>
                        <span data-purecounter-start="0" data-purecounter-end="7" data-purecounter-duration="1" class="purecounter"></span>
                        <p>Team</p>
                     </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script setup>

</script>

<style>

</style>