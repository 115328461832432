<template>
   <header id="header" class="header fixed-top">
      <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
         <router-link to="/" class="logo d-flex align-items-center">
            <img src="../assets/logo.png" alt="abr-logo" />
            <!-- <span>ABR</span> -->
         </router-link>

         <nav :class="{ 'navbar-mobile': isNavbarMobile }" id="navbar" class="navbar">
            <ul>
               <li><router-link class="nav-link scrollto active" to="#hero">Home</router-link></li>
               <li><router-link class="nav-link scrollto" to="#about">About Us</router-link></li>
               <li><router-link class="nav-link" to="/contact">Contact</router-link></li>
               <li><router-link class="getstarted scrollto" to="#about">Get Started</router-link></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle" :class="toggleIconClass" @click="toggleMobileNav"></i>
         </nav>
      </div>
   </header>
</template>

<script>
export default {
   name: 'Header',
   data() {
      return {
         isNavbarMobile: false,
         isToggleIconList: true
      };
   },
   computed: {
      toggleIconClass() {
         return this.isToggleIconList ? 'bi-list' : 'bi-x';
      }
   },
   methods: {
      toggleMobileNav() {
         this.isNavbarMobile = !this.isNavbarMobile;
         this.isToggleIconList = !this.isToggleIconList;
      },
      handleDropdownClick(e) {
         if (this.isNavbarMobile) {
         e.preventDefault();
         e.currentTarget.nextElementSibling.classList.toggle('dropdown-active');
         }
      },
      handleScrollToClick(e) {
         e.preventDefault();
         const hash = e.currentTarget.hash;
         if (hash) {
         const target = document.querySelector(hash);
         if (target) {
            this.scrollTo(target);
            if (this.isNavbarMobile) {
               this.toggleMobileNav();
            }
         }
         }
      },
      scrollTo(target) {
         window.scrollTo({
         top: target.offsetTop,
         behavior: 'smooth'
         });
      }
   },
   mounted() {
      document.querySelectorAll('.navbar .dropdown > a').forEach(element => {
         element.addEventListener('click', this.handleDropdownClick);
      });
      document.querySelectorAll('.scrollto').forEach(element => {
         element.addEventListener('click', this.handleScrollToClick);
      });
   },
   beforeDestroy() {
      document.querySelectorAll('.navbar .dropdown > a').forEach(element => {
         element.removeEventListener('click', this.handleDropdownClick);
      });
      document.querySelectorAll('.scrollto').forEach(element => {
         element.removeEventListener('click', this.handleScrollToClick);
      });
   }
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
