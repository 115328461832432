import { createRouter, createWebHistory } from 'vue-router'

import { isAuthenticated } from '../auth';
import Home from '../views/Home'

const website = 'Apps Built Right';
const descrip = 'AppsBuiltRight - Apps Built Right is specialized in creating innovative and user-friendly solutions that drive growth for businesses across industries. With a team of talented developers, designers, and strategists, we craft custom app solutions tailored to your unique needs, ensuring seamless functionality, intuitive interfaces, and maximum impact.';
const keywords = 'Ezra A., AppsBuiltRight, Apps Built Right, Freelancing, Freelancer, Skilled Software Engineer, Software Developer, Web Developer, Web Design, Web Development, Product Management, Graphic Design, PHP JavaScript ReactJS Developer, MySQL PostgreSQL Database, Database Expert, Nigerian Software Engineer, Custom App Solutions, Custom Web App, API Expert,  Restful API Integration';
const author = 'Ezra A., AppsBuiltRight, Apps Built Right';

const routes = [
   {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { 
         title: 'Home - ' + website,
         metaTags: [
            {
               name: 'description',
               content: descrip
            },
            {
               name: 'keywords',
               content: keywords
            },
            {
               name: 'author',
               content: author
            }
         ]
      },
      props: route => ({ route })
   },
   //404
   {
      path: '/:catchAll(.*)',
      redirect: '/',
   }
];

const router = createRouter({
   history: createWebHistory( process.env.BASE_URL ),
   routes
});

router.beforeEach(( to, from, next ) => {
   document.title = to.meta.title || 'Home - ' + website;
 
   if ( to.matched.some(record => record.meta.requiresAuth )) {
      if ( !isAuthenticated() ) {
         next({ name: 'Login' });
      } else {
         next();
      }
   } 
   else 
   {
      next();
   }
 });

export default router;