<template>
	<Hero></Hero>
	<About></About>
	<Counts></Counts>
</template>

<script setup>
	import { onMounted } from 'vue'
	import { useHead } from '@unhead/vue'
	import { useRoute } from 'vue-router'

	import Hero from '../components/Hero'
	import About from '../components/About'
	import Counts from '../components/Counts'
	/* import Button from '../components/utils/Button'
	import Alert from '../components/utils/Alert' */

	const route = useRoute()


	const initPureCounter = () =>
	{
		if ( typeof PureCounter !== 'undefined' ) 
		{
			new PureCounter();
		}
	};

	//***onMounted properties
	onMounted(() => {
		useHead({
			title: route.meta.title,
			meta: route.meta.metaTags.map( tag => ({
				name: tag.name,
				content: tag.content
			}))
		});

		initPureCounter();
	});
</script>